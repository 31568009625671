.zayavku table {
  margin: 20px auto;
}
.noformvs {
  font-size: 30px;
  text-align: center;
  margin: 50px;
}
.delete {
  display: flex;
  flex-flow: column nowrap;
}
.price {
  font-size: 18px;
  background: #ffffff;
}
.priceOne {
  text-align: center;
}
.other {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  color: black;
}

.price table {
  border: 0.1rem dotted gray;
  border-radius: 5px;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 20px;
}
.thleft {
  text-align: left;
}
.price table tr th {
  width: 80%;
  border: 0.1rem dotted gray;
}
.price table tr td {
  border: 0.1rem dotted gray;
}
tr:nth-child(1) {
  background-color: rgb(194, 194, 247);
}
tr:nth-child(2n + 3) {
  background-color: #e3e3ff;
}
tr:nth-child(even) {
  background-color: #fffdfd;
}
.price table .center {
  text-align: center;
  vertical-align: middle;
}
.outsidewidthtable .lastquestion {
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 20px;
  padding-left: 15%;
}
.outsidewidthtable p {
  position: relative;
  top: 0;
  left: 15%;
  padding-bottom: 20px;
}
.outsidewidthtable .attantion {
  color: rgb(205, 5, 5);
  border-bottom: none;
}
.outsidewidthtable p span {
  color: rgb(3, 3, 106);
  border-bottom: 2px dotted rgb(74, 74, 157);
}
/* Whoops404 */
.error404 {
  margin-top: 50px;
}
.error404 p {
  font-size: 20px;
  text-align: center;
}
.nofindresurs {
  margin: 20px auto;
  text-align: center;
}
.nofindresurs span {
  font-size: 20px;
  font-weight: 400;
}
.linkpath {
  font-size: 24px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
/* contacts */
.email {
  display: flex;

  margin: 0 auto;
  flex-direction: column;
  text-align: center;
  padding-bottom: 10px;
}
.mainContacts {
  margin-bottom: 40px;
}
.mainContacts h2 {
  text-align: center;
  margin: 50px auto 30px;
}
.contacts {
  font-size: 20px;
  margin-bottom: 15px;
  padding-left: 20px;
  width: 800px;
  margin: 0 auto;
}
.numerPhone span {
  display: block;
  width: 200px;
}
.numerPhone {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.whatsapp {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.whatsapp img {
  padding-right: 170px;
}
.sociatnetwork p {
  text-align: center;
}
.whatsapp,
.sociatnetwork,
.numerPhone {
  margin-bottom: 20px;
}

.sociatnetwork a:nth-child(1n + 2) {
  margin-top: 10px;
  display: inline-block;
  margin-left: 20px;
}
.planpracy h3,
.planpracy p {
  text-align: center;
}
.planpracy img {
  display: block;
  margin: 10px auto 10px;
  width: 300px;
}
.hofclean {
  padding-top: 10px;
  font-size: 22px;
  font-weight: 600;
}

/* gallary */
.containerPhoto {
  margin: 50px 0;
}
.allphoto {
  width: 830px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.3s;
}
.allphoto img {
  width: 150px;
  border-radius: 10px;
  border: 1px solid rgb(2, 184, 2);
}
.threerow img {
  width: 150px;
  height: 150px;
}
.allphoto .threerow .changephoto {
  width: 350px;
  height: 350px;
}
.tworow img {
  width: 150px;
  height: 200px;
}
.allphoto .tworow .changephoto {
  width: 350px;
  height: 400px;
}
.allphoto .changephoto {
  width: 350px;
  transition: 0.3s;
}
.onerow {
  width: 820px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tworow {
  width: 820px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.threerow {
  width: 820px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.fourrow {
  width: 820px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.galaryh2 {
  text-align: center;
  padding-bottom: 50px;
  color: black;
}
.one {
  display: flex;
  width: 400px;
  justify-content: space-between;
  align-items: center;
}
.two {
  width: 400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* O nas */
.containerOnas {
  padding-bottom: 30px;
}
.containerOnas h2 {
  box-shadow: 1px 1px 5px #021455;
  text-align: center;
  padding: 50px 0 20px;
}
.containerOnas img {
  display: block;
  width: 400px;
  margin: 20px auto 20px;
}
.containerOnas p {
  text-indent: 40px;
  text-align: justify;
  width: 800px;
  margin: 0 auto;
}
.onasP {
  padding-top: 30px;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 650px;
    margin: 0 auto;
  }
  .mainContacts p {
    width: 500px;
    font-size: 18px;
  }
  .contacts {
    width: 500px;
  }
  .allphoto {
    width: 630px;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
  }
  .allphoto img {
    width: 120px;
    border-radius: 10px;
    border: 1px solid rgb(2, 184, 2);
  }
  .allphoto .changephoto {
    width: 250px;
    transition: 0.3s;
  }
  .onerow {
    width: 620px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .tworow {
    width: 620px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .threerow,
  .fourrow {
    width: 620px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 700px) {
  .font {
    font-size: 15px;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 500px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 615px) {
  .allphoto {
    width: 600px;
    cursor: default;
    transition: 0.3s;
  }
  .onerow {
    display: block;
    width: 300px;
    margin: 20px auto;
  }
  .allphoto .onerow img {
    width: 300px;
    margin: 20px auto;
  }
  .tworow {
    width: 300px;
    display: block;
    margin: 20px auto;
  }
  .allphoto .tworow img {
    width: 300px;
    height: 350px;
    margin: 20px auto;
  }
  .threerow,
  .fourrow {
    display: none;
  }
  .galaryh2 {
    text-align: center;
    padding-bottom: 20px;
    color: black;
  }
}
@media screen and (max-width: 590px) {
  .font {
    font-size: 10px;
  }
  .containerOnas h2 {
    box-shadow: 1px 1px 5px #021455;
    text-align: center;
    padding: 30px 0 20px;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 500px;
    margin: 0 auto;
    font-size: 14px;
  }
  .allphoto {
    cursor: auto;
    width: 250px;
    display: inline;
  }
  .allphoto img {
    width: 250px;
    margin-bottom: 20px;
  }
  .price {
    font-size: 14px;
  }
  .mainContacts p {
    width: 400px;
    font-size: 16px;
  }
  .contacts {
    padding-left: 0;
    width: 300px;
  }
  .contacts p {
    width: 300px;
    padding-left: 0;
  }
  .mainContacts h2 {
    margin: 20px auto;
  }
  .wrapcontacts .link {
    font-size: 16px;
  }
  .phone {
    width: 300px;
    padding-left: 0px;
    text-align: center;
  }
  .wrapcontacts p {
    padding-left: 0;
    margin-left: 0;
    text-align: center;
  }
  .vibertelegram {
    margin-left: 100px;
  }
  .mail {
    width: 300px;
  }
  .numerPhone {
    width: 350px;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 540px) {
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 400px;
    margin: 0 auto;
    font-size: 14px;
  }
}
@media screen and (max-width: 425px) {
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 300px;
    margin: 0 auto;
    font-size: 12px;
  }
  .containerOnas img {
    display: block;
    width: 300px;
    margin: 20px auto 20px;
  }
  .mainContacts p {
    width: 300px;
  }
  .contacts {
    padding-left: 0;
    width: 300px;
  }
}
@media screen and (max-width: 400px) {
  .outsidewidthtable p {
    width: 200px;
  }
  .outsidewidthtable .lastquestion {
    width: 85%;
  }
}
@media screen and (max-width: 370px) {
  .email {
    font-size: 14px;
  }
  .whatsapp img {
    padding-right: 0;
    padding-left: 130px;
    padding-top: 10px;
  }
  .whatsapp {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone span {
    width: 250px;
    font-size: 18px;
    padding-left: 70px;
    padding-top: 15px;
  }
  .numerPhone p {
    font-size: 20px;
  }
  .wrapcontacts .link {
    font-size: 20px;
  }
}
@media screen and (max-width: 330px) {
  .whatsapp img {
    padding-right: 0;
    padding-left: 110px;
    padding-top: 10px;
  }
  .whatsapp {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone {
    width: 340px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone span {
    font-size: 18px;
    padding-left: 40px;
    padding-top: 15px;
  }
  .allphoto {
    width: 500px;
    cursor: default;
    transition: 0.3s;
  }
  .onerow {
    display: block;
    width: 250px;
    margin: 20px auto;
  }
  .allphoto .onerow img {
    width: 250px;
    margin: 20px auto;
  }
  .tworow {
    width: 250px;
    display: block;
    margin: 20px auto;
  }
  .allphoto .tworow img {
    width: 250px;
    height: 350px;
    margin: 20px auto;
  }
  .threerow {
    display: none;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 270px;
    margin: 0 auto;
    font-size: 12px;
  }
  .containerOnas img {
    display: block;
    width: 270px;
    margin: 20px auto 20px;
  }
  .mainContacts p {
    width: 250px;
    font-size: 14px;
  }
  .contacts {
    padding-left: 0;
    width: 250px;
  }
  .wrapcontacts .link {
    font-size: 13px;
  }
  .phone {
    width: 250px;
  }
  .vibertelegram {
    margin-left: 80px;
  }
  .mail {
    width: 250px;
  }
  .galaryh2 {
    text-align: center;
    padding-bottom: 0px;
    color: black;
  }
  .planpracy img {
    display: block;
    margin: 10px auto 10px;
    width: 250px;
  }
}
