.second_screen .container {
  text-align: center;
  padding-bottom: 100px;
}
.container {
  margin: 0 auto;
}
.second_screen .h3 {
  font-weight: 600;
  font-size: 55px;
  color: #333333;
  padding-top: 50px;
  text-transform: uppercase;
}

.second_screen .h1 {
  max-width: 932px;
  font-weight: 600;
  font-size: 35px;
  color: #333333;
  margin: 20px auto 8px;
  line-height: 1.3;
}
.second_screen .h2 {
  max-width: 700px;
  margin: 20px auto 55px;
  font-weight: 600;
}
.check {
  width: 200px;
  height: 54px;
  background-color: #0099ff;
  border-radius: 5%;
  margin: 30px 0 0 215px;
}
.check a {
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  font-size: 19px;
  padding: 14px;
}
.check:hover {
  background-color: #007acb;
}
